.placeholder-glow-side .placeholder-side {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite !important;
}

.placeholder-side {
    display: inline-block;
    min-height: 0.8em;
    width: 150px;
    color: #5a5252;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.8;
    /* border-radius: 10px; */
}

@keyframes placeholder-glow-side {
    50% {
        opacity: 0.5;
    }
}


.placeholder {
    background-color: currentcolor;
    cursor: wait;
    display: inline-block;
    min-height: 1em;
    opacity: .5;
    vertical-align: middle;
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
}


.show_more_text {
    background-color: #60707e;
    padding: 10px;
    opacity: .75;
}

.delete-img {
    /* background: rgba(82, 73, 62, 0.356); */
    z-index: 1;
    position: absolute;
    animation-duration: 4s;
    width: 95%;
    height: 90%;


}

.dl-btn {
    /* z-index: 2; */
    opacity: 1;
}

/* Custom Style of input */
.formCustom {
    /* padding: 10px; */
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

}

.inputCustom {
    border: none !important;
}

.inputCustom:focus {
    outline: none !important;
}

.iconUpload {
    cursor: pointer;
}

.sub_grid_wrapper_first_row {
    height: 200px !important;
}

.grid_wrapper {
    /* height: 250px !important; */
}